import { useState } from 'react';

function ContactUs(props) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [about, setAbout] = useState('');
    const [checkedUXUIDesign, setCheckedUXUIDesign] = useState(false);
    const [checkedBranding, setCheckedBranding] = useState(false);
    const [checkedGraphicDesign, setCheckedGraphicDesign] = useState(false);
    const [checkedAppDevelopment, setCheckedAppDevelopment] = useState(false);
    const [checkedWebDevelopment, setCheckedWebDevelopment] = useState(false);
    const [checkedAppFromScratch, setCheckedAppFromScratch] = useState(false);
    const [checkedSiteFromScratch, setCheckedSiteFromScratch] = useState(false);
    const [radioText, setRadioText] = useState('');
    const telegramBotToken = '6111293374:AAHqy2uHKxte8vvMDxiOcgRp4CdinLV_khU';
    // const telegramBotToken = '6660010162:AAFt6mcG1KOMbKSCZtvsHwu2eo8CTlY4WrI';
    const telegramChatId = '-1001901612835';
    const telegramApi = `https://api.telegram.org/bot${telegramBotToken}/sendMessage`;

    // function sendTelegram(e) {
    //     e.preventDefault();
        
    //     let text = `Name & Company: ${name}\nEmail: ${email}\nAbout: ${about}`;

    //     fetch(`https://api.telegram.org/bot${telegramBotToken}/sendMessage?chat_id=-1001901612835&text=test`, {
    //         method: "GET"
    //     })
    //     .then(success => {
    //         alert("Good!")
    //     }, error => {
    //         alert("Bad!")
    //         console.log(error);
    //     })
    // }

    function handleChangeName(e) {
        setName(e.target.value);
    }

    function handleChangeEmail(e) {
        setEmail(e.target.value);
    }

    function handleChangeAbout(e) {
        setAbout(e.target.value);
    }

    function handleChangeCheckboxUXUIDesign() {
        setCheckedUXUIDesign(!checkedUXUIDesign);
    }

    function handleChangeCheckboxBranding() {
        setCheckedBranding(!checkedBranding);
    }

    function handleChangeCheckboxGraphicDesign() {
        setCheckedGraphicDesign(!checkedGraphicDesign);
    }

    function handleChangeCheckboxAppDevelopment() {
        setCheckedAppDevelopment(!checkedAppDevelopment);
    }

    function handleChangeCheckboxWebDevelopment() {
        setCheckedWebDevelopment(!checkedWebDevelopment);
    }

    function handleChangeCheckboxAppFromScratch() {
        setCheckedAppFromScratch(!checkedAppFromScratch);
    }

    function handleChangeCheckboxSiteFromScratch() {
        setCheckedSiteFromScratch(!checkedSiteFromScratch);
    }

    function changeRadioText(e) {
        setRadioText(e.target.value);
    }

    async function sendTelegram(e) {
        e.preventDefault();

        const formBtn = document.querySelector('.contact-us__button');
        const textCheckbox = `${checkedUXUIDesign ? 'UX/UI Design' : ''} ${checkedBranding ? 'Branding' : ''} ${checkedGraphicDesign ? 'Graphic Design' : ''} ${checkedAppDevelopment ? 'App Development' : ''} ${checkedWebDevelopment ? 'Web Development' : ''} ${checkedAppFromScratch ? 'App from scratch' : ''} ${checkedSiteFromScratch ? 'Site from scratch' : ''}`;
        const text = `Name & Company: ${name}\nEmail: ${email}\nAbout: ${about}\nInterested: ${textCheckbox}\nProject budget: ${radioText}`;

        try {
            formBtn.textContent = 'Loading...';
            const response = await fetch(telegramApi, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    chat_id: telegramChatId,
                    text: text,
                })
            });

            if (response.ok) {
                setName('');
                setEmail('');
                setAbout('');
                setCheckedUXUIDesign(false);
                setCheckedBranding(false);
                setCheckedGraphicDesign(false);
                setCheckedAppDevelopment(false);
                setCheckedWebDevelopment(false);
                setCheckedAppFromScratch(false);
                setCheckedSiteFromScratch(false);
                setRadioText('');
                props.setInfoPopup(true);
                props.openPopup(true);
            } else {
                throw new Error(response.statusText);
            }
        } catch (error) {
            props.setInfoPopup(false);
            props.openPopup(true);
            console.error(error);
        } finally {
            formBtn.textContent = 'Submit the Request';
        }
    }

    return (
        <div className="contact-us">
            <div className="contact-us__title">
                <h1 className="contact-us__title-string contact-us__title-string_type_purple">have a great idea?</h1>
                <h1 className="contact-us__title-string">tell us about it</h1>
            </div>
            <form onSubmit={sendTelegram} className="contact-us__options">
                <div className="contact-us__name-info-container">
                    <div className="contact-us__name-container">
                        <p className="contact-us__text">Name & Company</p>
                        <input
                            className="contact-us__input"
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name"
                            value={name || ''}
                            onChange={handleChangeName}
                            minLength="1"
                            maxLength="40"
                            required
                        />
                    </div>
                    <div className="contact-us__name-container">
                        <p className="contact-us__text">Your Email</p>
                        <input
                            className="contact-us__input"
                            type="email"
                            name="email"
                            id="email"
                            placeholder="example@gmail.com"
                            value={email || ''}
                            onChange={handleChangeEmail}
                            minLength="7"
                            maxLength="50"
                            required
                        />
                    </div>
                </div>
                <div className="contact-us__checkbox-info-container">
                    <p className="contact-us__text">I'm interested in...</p>
                    <div className="contact-us__checkbox-container">
                        <label className="contact-us__checkbox">UX/UI Design
                            <input className="contact-us__checkbox-input" type="checkbox" checked={checkedUXUIDesign} onChange={handleChangeCheckboxUXUIDesign} />
                            <span className="contact-us__checkbox-span">UX/UI Design</span>
                        </label>
                        <label className="contact-us__checkbox">Branding
                            <input className="contact-us__checkbox-input" type="checkbox" checked={checkedBranding} onChange={handleChangeCheckboxBranding} />
                            <span className="contact-us__checkbox-span">Branding</span>
                        </label>
                        <label className="contact-us__checkbox">Graphic Design
                            <input className="contact-us__checkbox-input" type="checkbox" checked={checkedGraphicDesign} onChange={handleChangeCheckboxGraphicDesign} />
                            <span className="contact-us__checkbox-span">Graphic Design</span>
                        </label>
                        <label className="contact-us__checkbox">App Development
                            <input className="contact-us__checkbox-input" type="checkbox" checked={checkedAppDevelopment} onChange={handleChangeCheckboxAppDevelopment} />
                            <span className="contact-us__checkbox-span">App Development</span>
                        </label>
                        <label className="contact-us__checkbox">Web Development
                            <input className="contact-us__checkbox-input" type="checkbox" checked={checkedWebDevelopment} onChange={handleChangeCheckboxWebDevelopment} />
                            <span className="contact-us__checkbox-span">Web Development</span>
                        </label>
                        <label className="contact-us__checkbox">App from scratch
                            <input className="contact-us__checkbox-input" type="checkbox" checked={checkedAppFromScratch} onChange={handleChangeCheckboxAppFromScratch} />
                            <span className="contact-us__checkbox-span">App from scratch</span>
                        </label>
                        <label className="contact-us__checkbox">Site from scratch
                            <input className="contact-us__checkbox-input" type="checkbox" checked={checkedSiteFromScratch} onChange={handleChangeCheckboxSiteFromScratch} />
                            <span className="contact-us__checkbox-span">Site from scratch</span>
                        </label>
                    </div>
                </div>
                <div className="contact-us__checkbox-info-container">
                    <p className="contact-us__text">Project budget (USD)</p>
                    <div className="contact-us__checkbox-container">
                        <div className="contact-us__radio-button">
	                        <input id="radio-1" type="radio" name="radio" value="$2K" checked={radioText === '$2K' ? true : false} onChange={changeRadioText} />
	                        <label htmlFor="radio-1">$2K</label>
                        </div>
                        <div className="contact-us__radio-button">
	                        <input id="radio-2" type="radio" name="radio" value="$2K–$5K" checked={radioText === '$2K–$5K' ? true : false} onChange={changeRadioText} />
	                        <label htmlFor="radio-2">$2K–$5K</label>
                        </div>
                        <div className="contact-us__radio-button">
	                        <input id="radio-3" type="radio" name="radio" value="$5K–$10K" checked={radioText === '$5K–$10K' ? true : false} onChange={changeRadioText} />
	                        <label htmlFor="radio-3">$5K–$10K</label>
                        </div>
                        <div className="contact-us__radio-button">
	                        <input id="radio-4" type="radio" name="radio" value=">$10K" checked={radioText === '>$10K' ? true : false} onChange={changeRadioText} />
	                        <label htmlFor="radio-4">{'>'}$10K</label>
                        </div>
                    </div>
                </div>
                <div className="contact-us__about-info-container">
                    <div className="contact-us__name-container">
                        <p className="contact-us__text">Tell us more about your project</p>
                        <input
                            className="contact-us__input"
                            type="text"
                            name="about"
                            id="about"
                            placeholder="Something about your great idea"
                            value={about || ''}
                            onChange={handleChangeAbout}
                            minLength="0"
                            maxLength="300"
                        />
                    </div>
                </div>
                <div className="contact-us__button-container">
                    <button type="submit" className="contact-us__button">Submit the Request</button>
                </div>
            </form>
        </div>
    );
}

export default ContactUs;